import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { motion } from 'framer-motion'
import { useLocation } from '@reach/router';

export const NewsletterForm = ({ input, button }) => {
    const { pathname } = useLocation();

    function encode(data) {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    }

    return (
        <div>
            <Formik
                initialValues={{
                    email: '',
                }}

                onSubmit={(values, { setSubmitting, setStatus }) => {
                    fetch(pathname || '/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: encode({
                            'form-name': `newsletter-signup`,
                            form_name: `newsletter-signup`,
                            ...values,
                        }),
                    })
                        .then(() => {
                            setSubmitting(false);
                            setStatus('success');
                        })
                        .catch((error) => alert(error));
                }}
            >
                {({
                    onChange,
                    isSubmitting,
                    status,
                    values,
                    /* and other goodies */
                }) => (
                    <Form
                        className="w-full relative"
                        name="newsletter-signup"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        subject="Newsletter Signup"
                    >
                        {/* Hidden Fields for netlify */}
                        <input type="hidden" name="form-name" value="newsletter-signup" />
                        <p hidden>
                            <label>
                                <input name="bot-field" onChange={onChange} />
                            </label>
                        </p>
                        <>
                            <div className={`font-body transition-all delay-300 duration-300 absolute top-2 left-0 right-0 z-10 ${status === 'success'
                                ? 'opacity-100 pointer-events-all'
                                : 'opacity-0 pointer-events-none'
                                }`}>
                                <motion.h2
                                    className={`text-lg md:text-xl text-white`}
                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                >
                                    Thank you.
                                </motion.h2>
                            </div>
                            <div className={` w-full flex justify-between items-center border-b-4 border-liaise-yellow pb-3 pt-12 text-xl ${status === 'success' && 'opacity-0 pointer-events-none'}`}>
                                <Field name="email" className="text-liaise-yellow bg-liaise-orange font-regular w-full focus:outline-none" placeholder={input} id="email" type="text" />
                                <button type="submit" className="font-bold">{button}</button>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
