import React, { useState } from 'react'
import { AnimatePresence, motion } from 'react'
import Image from 'gatsby-plugin-sanity-image'

import { StandardModal } from './standardModal'
import { useSiteConfig } from '@querys/useSiteConfig'
import { PortableTextBlock } from '@modules/portableTextBlock'
import { NewsletterForm } from './newsletterForm'
import { StockistsModal } from '../stockists/stockistsModal'

const Close = ({ toggleOpen }) => (
    <button onClick={toggleOpen} className="col-start-11 col-end-12 flex justify-end items-start focus:outline-none">
        <svg className="w-max" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4.04687L4.04687 0L18.5 14.4531L32.9531 0L37 4.04687L22.5469 18.5L37 32.9531L32.9531 37L18.5 22.5469L4.04687 37L0 32.9531L14.4531 18.5L0 4.04687Z" fill="#FBEF89" />
        </svg>
    </button>
)

const Popup = ({ toggleOpen, type, storyPopup, nutritionPopup }) => {
    const { newsletterPopup } = useSiteConfig()

    switch (type) {
        case 'newsletter':
            return (
                <div className="newsletter-form popup bg-liaise-orange grid grid-cols-12 pt-14 pb-24">
                    <Close toggleOpen={toggleOpen} />
                    <div className="col-start-2 col-end-12 md:col-end-10 md:row-start-1 md:row-end-2">
                        <span className="block text-left font-extrabold">{newsletterPopup.heading}</span>
                        <p className="block text-left font-semibold">{newsletterPopup.copy}</p>
                    </div>
                    <div className="col-start-2 col-end-12">
                        <NewsletterForm input={newsletterPopup.placeholder} button={newsletterPopup.buttonText} />
                    </div>
                </div>
            )

        case 'story':
            return (
                <div className="popup history bg-liaise-orange grid grid-cols-12 pt-14 pb-24">
                    <Close toggleOpen={toggleOpen} />
                    <PortableTextBlock className="col-start-2 col-end-12 mt-16" text={storyPopup.storyCopy} />
                </div>
            )

        case 'stockists':
            return (
                <div className="popup stockists bg-liaise-orange grid grid-cols-12 py-14">
                    <StockistsModal onClose={toggleOpen} />
                </div>
            )

        default:
            return (
                <div className="popup nutrition bg-liaise-orange grid grid-cols-12 pt-14 pb-10">
                    <Close toggleOpen={toggleOpen} />
                    <div className="col-start-2 md:col-start-3 col-end-12 md:col-end-10 w-full md:row-start-1">
                        <span>DETAILS</span>
                        <ul>
                            {nutritionPopup.details.map((x, i) => (
                                <li key={i}>{x}</li>
                            ))}
                        </ul>
                        <span className="block pt-6">{nutritionPopup.heading}</span>
                        <p>{nutritionPopup.copy}</p>
                    </div>
                    <Image className="col-start-2 md:col-start-3 col-end-12 md:col-end-10 w-full" {...nutritionPopup.table} />
                </div>
            )
    }
}

export const Button = ({ type, text, flavourLink, storyPopup, nutritionPopup }) => {
    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(prev => !prev)
    return (
        <>
            {type === 'checkout' ?
                <a className="btn" href={flavourLink} target="_blank" rel="noreferrer">{text}</a>
                :
                <button onClick={toggleOpen} className={`${(type === 'nutrition') ? 'underline-important block left-important font-semibold mt-8 lg:mt-0' : type === 'story' ? 'story btn' : 'btn'}`}>
                    {text}
                </button>
            }

            <StandardModal isOpen={open} scroll={(type === 'story' || type === 'stockists') ? true : false}>
                {open && <Popup nutritionPopup={nutritionPopup} storyPopup={storyPopup} toggleOpen={toggleOpen} type={type} />}
            </StandardModal>
        </>
    )
}
