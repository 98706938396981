import React from 'react';
import PortableText from 'react-portable-text';

// Block Components
import {
  AnchorScrollLink,
  ExternalLink,
  PageAnchorLink,
  PageLink,
  PathLink,
} from '../ui/links';
import { ImageModule, VideoModule, Button } from '../modules';

export const PortableTextBlock = ({
  text,
  flavourLink,
  storyPopup,
  nutritionPopup,
  ...props
}) => {
  const serializers = {
    h1: (props) => <h1 {...props} />,
    h2: (props) => <h2 {...props} />,
    h3: (props) => <h3 {...props} />,
    h4: (props) => <h4 {...props} />,
    h5: (props) => <h5 {...props} />,
    h6: (props) => <h6 {...props} />,
    'super-script': (props) => <sup>{props.children}</sup>,
    'sub-script': (props) => <sub>{props.children}</sub>,
    pageLink: (props) => <PageLink {...props} className='underline' />,
    pathLink: (props) => <PathLink {...props} className='underline' />,
    anchorLink: (props) => <AnchorScrollLink {...props} className='btn' />,
    externalLink: (props) => <ExternalLink {...props} className='underline' />,
    pageAnchorLink: (props) => <PageAnchorLink {...props} className='btn' />,
    normal: (props) => <p {...props} />,
    imageModule: (props) => <ImageModule {...props} />,
    videoModule: (props) => <VideoModule {...props} />,
    button: (props) => (
      <Button
        flavourLink={flavourLink}
        storyPopup={storyPopup}
        nutritionPopup={nutritionPopup}
        type={props.type}
        text={props.popup}
      />
    ),
    fontWeight: (props) => (
      <span className={`font-${props.font}`}>{props.children}</span>
    ),
    link: (props) => (
      <a
        href={props.url}
        target={`${props.url?.includes('tel') ? '_self' : '_blank'}`}
        rel='noreferrer'
        className={`${
          props?.url?.includes('tel') ? null : 'underline-important'
        } pr-2`}
      >
        {props.children}
      </a>
    ),
  }; // eslint-disable-line

  return <PortableText content={text} serializers={serializers} {...props} />;
};
