import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import cx from 'classnames';

import CancelIcon from '@svg/exit.svg';

const SearchBox = ({ currentRefinement, refine, placeholder, isLarge }) => (
  <form noValidate action="" role="search" className="relative">
    <input
      type="text"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      className={cx(
        'mt-12 mb-8 border-b-2 font-regular pb-2 border-t-0 border-l-0 border-r-0 w-full pl-0 text-lg border-current focus:shadow-none focus:border-current focus:outline-none focus:ring-0 bg-transparent placeholder-current',
        {
          'border-b-4': isLarge,
          'text-3xl': isLarge,
        }
      )}
      placeholder={placeholder || 'Search'}
    />
    {isLarge && (
      <div
        className={cx(
          'absolute top-1/2 transform -translate-y-1/2 right-0 transition-opacity duration-300 cursor-pointer',
          {
            'opacity-0': currentRefinement,
          }
        )}
      >
        Search
      </div>
    )}
  </form>
);

export const CustomSearchBox = connectSearchBox(SearchBox);
