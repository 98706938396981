import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { InstantSearch, connectStateResults, Configure } from 'react-instantsearch-dom';

import CloseIcon from '@svg/exit.svg';
import { StockistHits } from './stockistHits';
import { CustomSearchBox } from './customSearchBox';
import { PortableTextBlock } from '@components/modules/portableTextBlock';
import { searchClient } from '@lib/algolia';
import { useSiteConfig } from '@querys/useSiteConfig'

const IndexResults = connectStateResults(({ searchState, searchResults, children }) =>
  searchState.query && searchResults && searchResults.nbHits !== 0 ? children : <></>
);

export const StockistsModal = ({ onClose }) => {
  const { stockistPopup } = useSiteConfig()

  return (
    <motion.div className="col-start-2 col-end-12 bg-sand-900 w-full relative md:mx-auto self-start overflow-y-scroll">
      <button className="absolute top-0 right-0" onClick={onClose}>
        <CloseIcon />
      </button>
      <div className="mt-32 md:mt-0">
        <h2 className="font-extrabold">{stockistPopup.heading}</h2>
        <h5 className="font-semibold">{stockistPopup.copy}</h5>
        <InstantSearch searchClient={searchClient} indexName="stockists">
          <Configure filters={`brands:Liaise`} hitsPerPage={600} />
          <CustomSearchBox placeholder="Search" />
          <IndexResults>
            <StockistHits />
          </IndexResults>
        </InstantSearch>
      </div>
    </motion.div>
  );
};
