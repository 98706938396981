import React from "react"
import AnchorScroll from "react-anchor-link-smooth-scroll"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

// Hooks & Querys
import { useSanityPath } from "../../hooks"

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  )
}

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  )
}

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  )
}

export const AnchorScrollLink = ({ anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll href={`#${anchor.current}`} {...rest}>
      {linkText}
    </AnchorScroll>
  )
}

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  )
}
