import React, { useEffect, useState } from 'react';
import { getDistance } from 'geolib';
import { connectHits } from 'react-instantsearch-dom';

import Pin from '@svg/pin.svg'

const Hit = ({ title, address, website, _geoloc }) => {
  const [currentPos, setCurrentPos] = useState({ lat: 0, lng: 0 })

  const geolib = require('geolib');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentPos({ lat: position.coords.latitude, lng: position.coords.longitude })
    });
  }, [])

  const distance = getDistance(
    { latitude: currentPos.lat, longitude: currentPos.lng },
    { latitude: _geoloc.lat, longitude: _geoloc.lng }
  )
  return (
    <div className="py-8 border-b-1 border-liaise-yellow">
      <h3 className="font-bold">{title}</h3>
      <p className="font-regular">{address}</p>
      {website && <a href={website}>View Website</a>}
      {_geoloc && (
        <div className="flex flex-row justify-between items-center">
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${_geoloc.lat},${_geoloc.lng}`}
            target="_blank"
            className="font-bold"
          >
            View Directions
          </a>
          {/* <span style={{ fontSize: '16px' }} className="flex gap-x-5">
            <Pin />
            {Math.round(geolib.convertDistance(distance, 'km') * 10) / 10 + 'km away'}
          </span> */}
        </div>
      )}
    </div>
  );
};

const Hits = ({ hits }) => {
  return (
    <div className="grid divide-y">
      {hits.map((hit) => (
        <Hit {...hit} />
      ))}
    </div>
  )
};

export const StockistHits = connectHits(Hits);
